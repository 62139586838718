import gsap from 'gsap/dist/gsap.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.min'
import Swiper from 'swiper/bundle'
import SimpleBar from 'simplebar'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

window.gsap = gsap
window.ScrollTrigger = ScrollTrigger
window.ScrollToPlugin = ScrollToPlugin
window.Swiper = Swiper
window.SimpleBar = SimpleBar
